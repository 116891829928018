/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderPopup {
    &.Popup {
        font-size: 14px;
        z-index: 99999;
    }

    .Popup-Content {
        @include desktop {
            min-width: 731px;
        }
    }

    &-Products {
        width: 100%;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
        }

        td,
        th {
            vertical-align: middle;
        }
    }

    &-ProductsWrapper {
        h4 {
            display: flex;
            align-items: center;
            gap: 15px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }
        }
    }

    &-Thumbnail {
        &.Image {
            width: 60px;
            height: 60px;
            padding: 0;
            flex-shrink: 0;
        }
    }

    &-Row {
        padding: 16px 0;
        display: flex;
        gap: 16px;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }

        &:not(:last-child) {
            @include mobile {
                border-bottom: 1px solid $grey;
            }
        }
    }

    &-Content {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;

        > * {
            margin-bottom: 4px;
        }
    }

    &-Product {
        &_brand {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        &_name {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }

        &_rowTotal {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-orderNumber {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: -0.2px;

        @include mobile {
            margin-bottom: 20px;
        }

        @include desktop {
            justify-content: flex-start;
            gap: 33px;
            padding-bottom: 12px;
            padding-top: 22px;
            border-bottom: 1px solid $grey;
            margin-bottom: 20px;
        }
    }

    dl {
        margin-bottom: 12px;

        @include desktop {
            margin-bottom: 8px;
        }
    }

    dt {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
    }

    dd {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        &.Pending {
            color: $orange;
            font-weight: 600;
        }

        &.Canceled {
            color: $errorred;
            font-weight: 600;
        }

        &.Complete {
            color: $green;
            font-weight: 600;
        }
    }

    td,
    th {
        min-width: 0;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
    }

    &-middleContent {
        margin-bottom: 20px;

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 12px;
        }
    }

    &-Actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 8px;
        margin-bottom: 0;

        @include desktop {
            flex-direction: row;
        }

        .Button {
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            &_isDisable,
            &.disabled {
                pointer-events: none;
                opacity: .25;
                cursor: not-allowed;
            }
        }
    }
}
