/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccountNewReturn {
    .amrma-attach {
        display: none;
    }

    &-CustomerAndAddressBlocks {
        display: flex;

        @include mobile {
            flex-direction: column;
        }

        > * {
            @include desktop {
                flex: 1;
            }
        }
    }

    &-Actions {
        margin-top: 20px;
        @include mobile {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        @include desktop {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        button {
            display: inline-block;
            background: $black;
            color: $white;

            @include mobile {
                min-height: 48px;
                width: 100% !important;
            }

            &:first-of-type {
                margin-right: 10px;
                @include mobile {
                    flex: 1
                }

                @include desktop {
                    margin-right: 20px;
                    max-width: 350px;
                    width: 100%;
                }
            }

            &:last-of-type {
                @include mobile {
                    width: max-content;
                }
            }
        }
    }

    &-MessageTitle {
        font-size: 17px;
        font-weight: normal;
        margin-top: 20px;

        @include desktop {
            font-size: 20px;
            margin-top: 45px;
        }
    }

    &-MessageAdditionalInfo {
        margin: 5px 0;
        font-style: italic;

        @include desktop {
            margin-top: 15px;
        }
    }

    &-MessageTextArea {
        textarea {
            font-size: var(--font-size);
            height: 120px;
            width: 100%;
            @include desktop {
                height: 150px;
            }
        }
    }

    &-MessageAttachmentButton {
        align-items: center;
        display: flex;
        font-size: var(--font-size);
        margin-top: 10px;

        svg {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }

    &-CustomerAndAddressBlocks {
        display: flex;
        @include mobile {
            flex-direction: column;
        }

        > * {
            @include desktop {
                flex: 1;
            }
        }

        >:first-child {
            @include mobile {
                margin-bottom: 15px;
            }

            @include desktop {
                margin-right: 15px;
            }
        }
    }
    &-Policy {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        @include desktop {
            width: 100%;
            justify-content: flex-end;
        }

        .Field {
            margin-top: 2px;
        }
        p {
            top: 2px;
            margin-bottom: 0;
        }
    }

    &-PaymentMethod {
        h3 {
            background: $icegrey;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            padding: 12px 16px;
            margin: 10px -16px;

            @include desktop {
                margin: 25px 0;
            }
        }

        &-Details {
            p {
                margin-top: 8px;
                color: $color-grey-A1;
                line-height: 22px;
                letter-spacing: -0.0008em;
                margin-bottom: 28px;

                @include desktop {
                    margin-bottom: 35px;
                }
            }
        }

        &-Fields {
            @include desktop {
                display: flex;
                gap: 16px;
            }

            .Field {
                @include desktop {
                    width: 50%;
                    margin-top: 0;
                }

                input {
                    @include desktop {
                        width: 100%;
                    }
                }
            }
        }
    }

    .MyAccount-Heading {
        @include mobile {
            font-size: 17px;
            margin-top: 14px;
        }
    }
}
