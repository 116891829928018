/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.RequestAccountDeleteButton {
  margin-top: 10px;

  &Overlay {
    &_isVisible {
      &.Overlay {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        padding: 50px;
        text-align: center;

        &:not(.Overlay_isStatic) {
          @include mobile {
            left: 50% !important;
            top: 50%;
            width: 95%;
            height: auto;
            padding: 20px;
          }
        }

        button {
          width: auto;
          margin: 0 5px;
        }
      }
    }

    &_bkg {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0,0,0,.4);
      display: block;
      z-index: 101;
    }
  }
}
