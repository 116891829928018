/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-content-background: #{$white};
    --my-account-wrapper-margin-mobile: 16px;
    --my-account-content-border: 1px solid rgba(10, 9, 3, 0.16);
}

.MyAccount {
    background: var(--my-account-background);

    &-Wrapper {
        &.ContentWrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto 1fr;
            grid-column-gap: 40px;
            grid-row-gap: 0;
            padding-block-start: 0;
            padding-block-end: 12px;

            @include mobile {
                grid-column-gap: 28px;
                padding: 0;
                grid-template-columns: 1fr;
            }
        }
    }

    &-TabContent {
        background: var(--my-account-content-background);
        @include mobile {
            margin-inline: var(--my-account-wrapper-margin-mobile);
            margin-top: -36px;
        }

        @include desktop {
            grid-row: span 2;
        }
    }

    &-Heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        padding-bottom: 32px;
        padding-top: 32px;
        text-align: center;

        @include desktop {
            padding-top: 0;
            padding-bottom: 0;
        }

        h1 {

            @include desktop {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }

    &_backButton {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        height: 48px;
        margin-bottom: -12px;
        margin-left: -1px;
        background: $white;

        &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent $black $black transparent;
            transform: rotate(135deg);
        }
    }

    &-mainbanner {
        &.ContentWrapper {
            padding: 0;

            @include mobile {
                margin-top: -48px;
            }

            @include desktop {
                padding-left: 48px;
                padding-right: 48px;
            }

            img {
                vertical-align: top;
            }

            @include ultra-narrow-desktop {
                height: 144px;

                img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 48px;
                    right: 48px;
                    margin: auto;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                    width: calc(100% - 96px);
                    min-height: 100%;
                }
            }
        }
    }

    &-InfoContent {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 26px 19px;

        @include desktop {
            padding: 32px 52px;
            left: 48px;
            right: 48px;
        }

        h3 {
            color: $grey2D2D2D;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin: 0;

            @include desktop {
                color: $white;
            }
        }

        h2 {
            color: $grey2D2D2D;
            font-family: $font-BlackerProDisplay;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            margin: 0;

            @include desktop {
                color: $white;
            }
        }
    }
}
