/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
}

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 55px;

    &-Heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin: 0 0 32px;
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        @include desktop {
            font-size: 18px;
            line-height: 26px;
            margin: 10px 0 0;
            width: 50%;
            z-index: 1;
            flex-shrink: 0;
        }

        &.main {
            @include mobile {
                margin-bottom: 24px;
            }
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            flex-shrink: 0;
            margin: 0;
            width: auto;
        }
    }

    &-goBack {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        display: inline-flex;
        gap: 12px;
        align-items: center;
        padding: 4px 8px;
        margin-bottom: 20px;
        margin-left: -14px;
        cursor: pointer;

        @include desktop {
            position: absolute;
            top: 0;
            left: 0;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &-Products {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
    }

    &-NoProducts {
        @include tablet {
            position: relative;
            top: 60px;
        }
    }

    &-ActionBar {
        display: flex;
        padding: 0;
        margin-bottom: 24px;
        padding-bottom: 24px;
        justify-content: flex-end;
        border-bottom: 1px solid $grey;
        flex-direction: column;

        @include desktop {
            width: 50%;
            padding-block-end: 4px;
            margin-bottom: 0;
            flex-wrap: wrap;
            flex-direction: row;
            border-bottom: 0 none;
            flex-shrink: 0;
            gap: 20px;
        }

        @include tablet {
            .AddToCart-Icon {
                display: none;
            }
        }

        .Button {
            border: 0 none !important;
        }
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include desktop {
            width: auto;
        }
    }

    &-top {
        @include desktop {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-top: 52px;
        }

        &.main {
            @include desktop {
                padding-top: 0;
                margin-bottom: 16px;
                flex-direction: row-reverse;
            }
        }

        &.empty {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding-top: 0;

            h2 {
                display: none;

                @include desktop {
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    margin: 0 0 20px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                max-width: 474px;
                margin-bottom: 26px;
            }

            .MyAccountMyWishlist-ActionBarActionWrapper {
                width: auto;
            }
        }
    }

    &-NoProducts {
        padding-top: 26px;
        text-align: center;
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Button {

        @include mobile {
            width: 100%;
        }

        .CartIcon {
            fill: var(--color-white);
            margin-inline-end: 12px;
            width: 20px;
            height: 20px;

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearRemoveItemsButton {
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ClearWishlistButton {
        font-weight: bold;

        @include desktop {
            order: 1;
        }

        &.Button {
            width: auto;
        }
    }

    &-ShareWishlistButton {
        .ShareIcon {
            margin-inline-end: 12px;
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: var(--primary-dark-color);
        }
    }
}
