/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccountReturnDetailsChat {
    &-Title {
        font-weight: normal;
        line-height: 1;
        font-size: 17px;
        margin-top: 30px;
        margin-bottom: 20px;
        @include desktop {
            font-size: 20px;
            margin-top: 45px;
        }
    }

    &-ContentWrapper {
        background-color: #f9f9f9;
        padding: 20px 10px 10px;
        @include desktop {
            padding: 35px 25px 20px;
        }
    }

    &-InputSectionWrapper {
        background-color: #FFF;
        padding: 10px 15px;
        display: flex;
        margin-top: 35px;
        align-items: center;

        button {
            @include mobile {
                width: auto;
            }
        }
    }

    &-InputSectionTextArea {
        flex: 1;
        margin: 0 15px;
        width: 100%;
        border-color: #FFF;
        font-size: 16px;
        padding: 0;
        height: 100%;

        &::placeholder {
            color: #b0adad;
            font-size: 16px;
        }

        &:focus {
            border-color: #FFF;
        }
    }
}
