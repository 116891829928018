/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressTable {
    font-size: 14px;
    padding: 10px 16px 2px;
    border: 1px solid $black;

    td,
    th {
        @include desktop {
            width: 50%;
        }
    }

    &-CustomerInfo {
        margin-bottom: 40px;

        @include desktop {
            min-height: 165px;
        }

        &_col {
            font-weight: 400;
            font-size: 14px;
            line-height: 33px;
            letter-spacing: -0.0008em;
        }

        &_default_address {
            color: $grey727272;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 12px;
            order: 1;

            &:empty{
                order: 0;

                @include mobile {
                    margin-bottom: 0;
                }
            }

            @include desktop {
                min-height: 24px;
            }
        }

        &_default_addresses {
            display: flex;
            flex-direction: column;
        }
    }

    &-Actions {
        border-top: 1px solid $grey;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Button {
            padding: 0 !important;
            gap: 12px;
            font-size: 12px !important;
            width: auto;
            margin: 0 !important;
        }
    }
}
