/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    @include desktop {
        grid-template-columns: 1fr 1fr;
    }

    @include tablet {
        grid-template-columns: 1fr;
    }

    &-DefaultAddress {
        @include desktop {
            grid-row: 2;
        }
    }

    &-CustomerData {
        @include desktop {
            padding-right: 12%;
        }

        @include tablet {
            padding-right: 0;
        }
    }

    &-Info {
        margin-block-end: 5px;
    }

    button + button {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            margin-inline-start: 12px;
        }
    }
}
