/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressBook {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;

    @include desktop {
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }

    @include wide-desktop {
        grid-template-columns: repeat(3, 1fr);
    }

    &.noaddress {
        display: flex;
        flex-direction: column-reverse;
        gap: 2px;
        align-items: center;
    }

    &-Button {
        &.Button {
            background: $black;
            color: $white;

            @include desktop {
                position: absolute;
                inset-block-start: -69px;
                inset-inline-end: 0;

                .noaddress & {
                    position: static;
                }
            }

            &:not([disabled]):hover {
                background: $black;
                color: $white;
            }
        }
    }

    button + button {
        margin-inline-start: 12px;

        @include mobile {
            margin-block-start: 14px;
            margin-inline-start: 0;
        }
    }

    &-title {
        display: none;

        @include desktop {
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin-bottom: 32px;

            &.noaddress {
                display: none;
            }
        }
    }
}
