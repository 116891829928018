/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressPopup {
    font-size: 12px;

    &-Address {
        margin: 12px 0;

        @include mobile {
            margin: 14px 0;
        }
    }

    .Button {
        @include mobile {
            width: 100%;
        }
    }

    .MyAccount-Button {
        &.Button {
            background: $black;
            color: $white;
        }
    }

    .FieldForm {
        @include desktop {
            text-align: right;
        }
    }

    .FieldForm-Fields {
        grid-column-gap: 14px;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        max-width: 686px;

        @include desktop {
            grid-column-gap: 17px;
        }

        .Field_type_checkbox {
            grid-column: 1 / -1;
        }

        .Field {
            width: 100%;

            @include desktop {
                width: calc(50% - 9px);
            }

            &.Field_type_checkbox {
                width: 100%;
            }

            &.telephone {
                width: calc(65% - 7px);

                @include desktop {
                    width: calc(31% - 6px);
                }
            }

            &.postcode {
                width: calc(35% - 7px);

                @include desktop {
                    width: calc(17% - 6px);
                }
            }
        }
    }
}
