/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCustomerPopup {
  .FieldForm-Fields {
    margin-bottom: 36px;

    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 17px;
    }
  }

  .MyAccount-Button {
    &.Button {
      background: $black;
      color: $white;
    }
  }
}