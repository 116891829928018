/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CreateAccount {
    .ContentWrapper {
        padding-block-start: 20px;
        padding-block-end: 50px;
        padding-inline: 20px;
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        .MyAccountOverlay {
            &-Additional {
                &:not(.CreateAccount-Additional) {
                    display: none;

                    &.MyAccountOverlay-Additional_state_confirmEmail {
                        display: block;
                    }
                }
            }
        }
    }

    &-CreateAccountWrapper {
        .Field {
            &.date {
                .Image-Image {
                    width: auto;
                    height: auto;
                    top: 11px;
                    right: 23px;
                    left: auto;

                    + input[type="date"] {
                        background: none;
                    }
                }
            }

            input {
                &[type="date"] {
                    color: transparent;
                    padding-top: 9px;
                    padding-bottom: 8px;
                    min-height: 47px;

                    ~ .Field-Placeholder {
                        background: $white;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        position: absolute;
                        left: 1px;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 9px 16px 8px;
                        white-space: nowrap;
                    }
                }

                &::-webkit-calendar-picker-indicator {
                    font-size: 24px;
                    opacity: 0;
                }
            }
        }
    }

    .Loader {
        margin: 0;
    }

    input {
        width: 100%;

        &[type='checkbox'] {
            width: auto;
        }
    }
}
