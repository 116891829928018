/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccount {
    &-EnhanchedRegistration {
        &-PersonalInformation {
            padding: 0 16px;

            @include desktop {
                padding: 0;
                position: static;
            }
        }
        --content-wrapper-width: 100%;
        padding: 0;
        overflow: hidden;

        @include desktop {
            max-width: 1476px;
        }

        &_step-1,
        &_step-4 {
            @include desktop {
                padding: 143px 0 243px;
                width: 90%;
                max-width: 804px;
                margin: 0 auto;
                position: static;
            }

            @include wide-desktop {
                max-width: 1104px;
                padding-left: 300px;
            }

            .MyAccount-EnhanchedRegistration_welcome_page_content {
                @include desktop {
                    background: $white;
                    padding: 88px 32px 12px;
                }
            }

            .MyAccount-EnhanchedRegistration-Action {
                @include desktop {
                    background: $white;
                    padding: 0 32px 88px;
                }
            }
        }

        &_step-2,
        &_step-3 {
            @include desktop {
                padding-left: 40%;
                position: static;
                padding-right: 60px;
                padding-top: 32px;
                padding-bottom: 60px;
            }

            @include wide-desktop {
                padding-left: 594px;
            }

            //@media (min-width: 1900px) {
            //    padding-right: 594px;
            //}

            .MyAccount-EnhanchedRegistration-Action,
            .MyAccount-EnhanchedRegistration-PersonalInformation {
                @include desktop {
                    max-width: 982px;
                }

                @media (min-width: 1900px) {
                    margin: 0 auto;
                }
            }
        }

        &_step-3 {
            .MyAccountRegistrationForm-title_form {
                margin-bottom: 12px;
            }
        }

        &_welcome_page {
            position: static;

            &_image {
                position: static;

                img {
                    position: absolute;
                    object-position: 50% 50%;
                    object-fit: contain;
                    height: auto !important;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 0;

                    @include mobile {
                        max-width: 100%;
                    }

                    @include desktop {
                        width: auto !important;
                    }
                }
            }

            &_content {
                padding: 163px 16px 25px;

                @include desktop {
                    padding: 0;
                }
            }

            &_title {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
                margin: 0 0 16px;

                @include desktop {
                    margin-bottom: 20px;
                }

                .MyAccount-EnhanchedRegistration_step-4 & {
                    @include mobile {
                        text-align: center;
                    }
                }
            }

            &_text {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                margin: 0 0 16px;

                @include desktop {
                    margin-bottom: 20px;
                }
            }

            &_list {
                font-size: 14px;
                line-height: 22px;
                margin: 0;

                li {
                    font-size: 14px;
                    line-height: 22px;
                    margin: 0 0 12px;
                }

                &_title {
                    margin: 0 0 16px;
                    text-decoration: underline;

                    @include desktop {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &-Action {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            margin-bottom: 18px;

            @include desktop {
                flex-direction: row;
                justify-content: flex-end;
            }

            .MyAccount-EnhanchedRegistration-PersonalInformation + & {
                flex-direction: row;
                justify-content: space-between;
                padding: 0 15px;
            }

            .Button {
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.04em;
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 14px;
                padding: 0;

                @include desktop {
                    margin-left: 32px;
                }

                &:not(.skip) {
                    background: $black;
                    color: $white;
                    min-width: 140px;
                    min-height: 48px;
                    padding: 8px;
                }

                &.skip {
                    color: #000;
                    background: none;
                    border: 0 none;
                    height: auto;
                    text-decoration: underline;

                    &:not([disabled]):hover {
                        background: none;
                        border: 0 none;
                        color: $black;
                        padding: 0;
                    }
                }
            }
        }

        form {
            position: static;
        }

        &_dashboard {
            @include desktop {
                border-left: 1px solid $grey;
                padding-left: 10.5%;
            }

            @include tablet {
                border-left: 0;
                border-top: 1px solid $grey;
                padding-left: 0;
                padding-top: 24px;
            }

            .MyAccount-EnhanchedRegistration_welcome_page_title {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                margin-bottom: 12px;

                @include mobile {
                    display: none;
                }
            }

            .MyAccount-EnhanchedRegistration_welcome_page_list li {
                padding-left: 16px;

                &:before {
                    left: 0;
                }
            }

            &-Button {
                gap: 12px;
                margin-top: 12px;

                @include mobile {
                    background: $black;
                    color: $white;
                }

                img {
                    width: 24px !important;
                    height: 24px !important;

                    @include mobile {
                        display: none;
                    }
                }
            }

            .ExpandableContent-Button {
                gap: 20px;
                align-items: flex-start;

                .AddIcon,
                .MinusIcon,
                .ChevronIcon,
                svg {
                    margin-top: 4px;
                }
            }

            .ExpandableContent-Heading {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: -0.2px;

                > span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    display: block;
                    word-break: break-word;
                    margin-top: 12px;
                }
            }

            &-Details {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 12px;
                margin-bottom: 12px;
                margin-top: 16px;

                @include desktop {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &-List {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin: 0 !important;

                .ProductReviews & {
                    display: flex;
                    gap: 8px;

                    > * {
                        white-space: nowrap;
                        margin: 0;
                    }

                    label {
                        font-size: 14px;
                        font-weight: 600;

                        &:after {
                            content: ":";
                        }
                    }
                }

                &:before {
                    display: none;
                }

                > label {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    margin-bottom: 12px;

                    @include desktop {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                span {
                    display: flex;
                    gap: 12px;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-EnhancedCmsBlock {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        @include desktop {
            width: calc(40% - 20px);
        }

        @include wide-desktop {
            width: 530px;
        }
    }
    &-EnhancedContent {
        position: absolute;
        bottom: 30%;
        left: 0;
        right: 0;
        padding: 0 15px;
        margin: 0 auto;
        max-width: 410px;

        h3 {
            color: $white;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin: 0 0 20px;
        }

        li {
            color: $white;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;

            &:before {
                left: 0;
                position: static;
            }
        }
    }
}