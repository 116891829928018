/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountTabList {
    &-ExpandableContentContent {
        &.ExpandableContent-Content {
            max-height: 100%;
            opacity: 1;
            margin-block-start: 0;
            padding: 0 16px;

            @include desktop {
                padding: 0;
            }
        }


        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }
        }
    }

    &-ExpandableContentButton {
        padding-inline: 16px;

        &.ExpandableContent-Button {
            display: none;
        }

        &::before,
        &::after {
            inset-inline-end: 24px;

            @include mobile {
                inset-inline-end: 28px;
            }
        }
    }
}
