/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountMyOrders {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    @include mobile {
        grid-gap: 28px;
    }

    &.noOrder {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
    }

    &-Button {
        &.Button {
            background: $black;
            color: $white;

            @include desktop {
                position: absolute;
                inset-block-start: -69px;
                inset-inline-end: 0;

                .noOrder & {
                    position: static;
                    min-width: 301px;
                }
            }

            &:not([disabled]):hover {
                background: $black;
                color: $white;
            }
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            grid-template-columns: 1fr;
        }

        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }
    }
}
