/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountCustomerTable {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    @include desktop {
        grid-gap: 28px;
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    &-CustomerInfo {
        &:not(:last-child) {
            border-bottom: 1px solid $grey;
        }

        &_title {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin: 0 0 12px;
        }

        &_content {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;

            @include desktop {
                margin-bottom: 34px;
                display: flex;
                align-items: center;
                gap: 30px;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            @include ultra-wide-desktop {
                flex-wrap: nowrap;
            }

            @include ultra-narrow-desktop {
                display: block;
            }
        }

        &_col {
            margin-bottom: 24px;

            @include desktop {
                margin-bottom: 0;
            }

            @include ultra-narrow-desktop {
                margin-bottom: 24px;
            }

            &:last-child {
                @include wide-desktop {
                    flex-shrink: 0;
                }
            }

            .Button {
                gap: 12px;

                @include wide-desktop {
                    min-width: 239px;
                }
            }
        }
    }
}
