/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}

.MyAccountReturnTableRow {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px;

    &.total,
    &.order_no {
        letter-spacing: -0.2px;

        label {
            font-size: 16px;
            line-height: 24px;
        }

        span {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &.Actions {
        .Button {
            width: 100%;
        }
    }

    &-DownloadLink {
        display: block;
    }

    &s {
        font-size: 14px;
        padding: 20px 16px 8px;
        border: 1px solid $black;
    }

    &_seperator {
        border-bottom: 1px solid $grey;
        margin: 16px 0;
    }

    label {
        font-weight: 600;
    }

    &-Button {
        &.Button {
            margin: 12px 0 0;

            @include desktop {
                width: 100%;
            }
        }
    }
}
