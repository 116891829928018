/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccountRegistrationForm {
  &-title_form {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    margin-bottom: 24px;

    @include desktop {
      font-size: 18px;
      line-height: 26px;
    }

    &:before {
      content: attr(data-step) ". ";
    }
  }

  &-title_page {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
    padding: 12px 16px;
    margin: 0 -16px 22px;
    background: $xryso;

    @include desktop {
      background: none;
      font-size: 20px;
      line-height: 28px;
      padding: 0;
      margin: 0 0 25px;
    }
  }

    &-Heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    }

    ul {
        list-style: none;
        margin: 0 0 33px;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        @include mobile {
          flex-wrap: wrap;
        }

        @include desktop {
          margin-bottom: 28px;
          padding-bottom: 39px;
          border-bottom: 1px solid $grey;
          gap: 90px;
          row-gap: 40px;
        }
      }
      
      li {
        margin-bottom: 0;

        @include mobile {
          flex: 50%;
        }

        &:before {
          display: none;
        }
      }
      
      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        &.FieldMiultiSelectDropDown-Label {
          margin: 12px 0;
        }
      }

  .Field {
    margin-top: 26px;

    @include desktop {
      margin-top: 23px;
    }
  }
      
      input[type="radio"] {
        margin: 0 10px 0 0;
      }

    &-SignInButton {
        margin-block-start: 20px;

        button {
            width: 100%;
        }
    }

    #forgot-password-label {
        margin-block-end: 0;
    }

    .CreateAccount {
        margin-inline-start: 5px;
    }

  &-List {
    .Field [type='checkbox'] {
      width: 100%;
      min-width: 100%;
      min-height: 100%;
      cursor: pointer;
    }
  }
}
