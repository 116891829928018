/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountNewsletterSubscription {
    font-size: 14px;

    @include desktop {
        display: block;
        margin: 0 auto 20px;
        max-width: 500px;
    }

    &-ButtonWrapper {
        text-align: center;

        @include desktop {
            margin: 0 auto 20px;
            max-width: 500px;
            text-align: left;
        }

        .Button {
            min-width: 150px;
        }
    }

    .Field {
        margin-top: 12px;
    }

    .Field_type_checkbox {
        height: 0;
        width: 0;
        overflow: hidden;
        opacity: 0;
    }

    label {
        span {
            a {
                font-weight: 600;
                padding: 0 5px;
            }
        }
    }

    .checkradio {
        display: inline-flex;

        input[type='checkbox'] {
            display: none;
        }

        &__box {
            width: 14px;
            height: 14px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            flex-shrink: 0;
            margin-right: 11px;
        }

        &__input:checked + .checkradio__box {
            border-width: 4px;
            border-color: $black;
        }

        &__content {
            font-size: 12px;
            line-height: 16px;

            a {
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}
