/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccountNewReturnItemSelect {
    &-Title {
        font-weight: normal;
        line-height: 1;
        font-size: 17px;
        @include mobile {
            margin-top: 20px;
        }

        @include desktop {
            font-size: 20px;
        }
    }

    &-ItemWrapper {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        padding: 0;

        @include desktop {
            border-bottom: solid 1px #ebebea;
            gap: 20px;
        }

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        .CartItem-Wrapper {
            order: 2;
            display: flex;
            padding: 10px 15px;
            gap: 20px;

            @include mobile {
                flex-direction: column;
                padding: 0;
            }
        }
    }

    &-RowTotal {
        padding: 0 0 20px;
        text-align: center;

        @include desktop {
            padding: 20px 0;
        }
    }

    &-ItemInnerWrapper {
        display: flex;
        padding: 28px 0;
        align-items: center;

        @include mobile {
            flex-direction: column;
            padding-bottom: 0;
        }

        > div {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .CartItem-Picture.Image {
            @include desktop {
                width: 112px;
                height: 112px;
            }
        }
    }

    &-ReasonBlock {
        padding: 28px 0;

        @include mobile {
            position: static;
            padding-top: 0;
            width: 100%;
        }

        label {
        input {
            min-width: 16px !important;
            cursor: pointer;
         }
        }
    }

    &-ItemField {
        flex: 1;
        flex-basis: 30px;
        flex-grow: 0;
        flex-shrink: 0;
        order: 1;
        margin: 0;
        height: max-content;
        text-align: right;

        @include mobile {
            position: static;
        }

        @include desktop {
            margin-top: 10px;
            margin-bottom: 25px;
        }

        &_isDisabled {
            // &[type='checkbox']:disabled + label {
            //     &:last-of-type {
                    cursor: not-allowed;
            //     }
            // }
        }

        label {
            font-weight: normal;
        }

        label:first-of-type {
            padding-left: 16px;
        }

        label:last-of-type {
            @include mobile {
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        input {
            // min-width: 15px !important;
        }
    }

    &-ReasonBlockWrapper {

        @include desktop {
            order: 3;
            width: 100%;
        }
    }

    &-SelectWrapper {
        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }

    &-SelectTitle {
        font-size: 14px;
        font-weight: bold;

        @include desktop {
            font-size: 16px;
        }
    }

    &-SelectInput {
        margin-top: 5px;

        .Field-Select {
            @include mobile {
                font-size: var(--input-mobile-size);
            }
        }
    }

    &-QtyBlockWrapper {
        font-size: var(--font-size);
        display: flex;
        align-items: center;

        @include desktop {
            justify-content: center;
        }

        .Field_type_number {
            margin: 0 5px;
            border: 1px solid $black;
            margin-block: 0;
            margin-inline-end: 12px;

            .amgiftcard & {
                display: none !important;
            }

            input {
                font-weight: 500;
                font-size: 12px;
                min-width: 41px;
                letter-spacing: -0.0008em;
                cursor: pointer;
                border: 0 none;
                height: 46px;
                width: 41px;

                &:hover {
                    border-color: var(--primary-dark-color);
                }
            };

            [type='number'] ~ button {
                cursor: pointer;
                border: 0 none;
                height: 46px;
                width: 41px;

                &:hover {
                    border-color: var(--primary-dark-color);
                }
            }

            &.Field_hasError {
                margin-bottom: 10px;

                .Field-Message {
                    position: absolute;
                }

                button {
                    &:last-of-type {
                        bottom: 0;
                    }
                }
            }

            input {
                font-size: var(--font-size);
            }

            button {
                @include mobile {
                    width: unset;
                    font-size: 1.3rem;
                }
            }
        }
        span { 
            &:last-child {
                display: flex;
                &::before {
                    content: '/';
                    padding-right: 1px;
                }
            }
        }
    }

    &-ItemImagePlaceholder {
        &::after {
            height: 160px;
            display: inline-block;
            width: 110px;
            margin: 25px 1rem 25px 0;
        }
    }

    &-ReasonBlockRuleTitle,
    &-ReasonBlockRule {
        font-size: var(--font-size);
        display: block;
    }

    &-ReasonBlockWrapper_isRulesBlock {
        @include mobile {
            position: absolute;
            left: 0;
            right: 0;
            top: 28px;
            min-height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-ReasonBlockRuleTitle {
        background: rgba(249, 249, 249, 0.5);
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        padding: 12px 8px;

        @include desktop {
            background: $icegrey;

            @include desktop {
                padding: 12px 32px;
            }
        }
    }

    &-Product {
        &_brand {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin: 0 0 2px;

            @include desktop {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                margin-bottom: 4px;
            }
        }

        &_name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            @include desktop {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
        }

        &_sku {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            margin-bottom: 7px;

            @include mobile {
                color: $color-grey-A1;
            }

            @include desktop {
                margin-bottom: 26px;
            }
        }
    }
    table {
        width: 100%;
    }
}
