/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MyAccountMyReturns {
    @include mobile {
        margin-top: 10px;
    }

    &-New {
        margin-bottom: 24px;

        @include desktop {
            margin-bottom: 32px;
        }
    }

    &-DropdownWrapper {
        display: flex;
        width: 100%;
        max-width: 526px;
        flex-direction: column;
        gap: 16px;

        @include desktop {
            flex-direction: row;
        }

        button {
            background: $black;
            color: $white;

            @include desktop {
                flex: 1;
            }

            &.Button:not([disabled]):hover {
                background: $white;
                color: $black;
            }
        }
    }

    &-Bottom {
        border-top: 1px solid $grey;
        padding: 24px 0;

        @include desktop {
            padding: 32px 0;
        }

        h2 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin: 0 0 24px;

            @include desktop {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
    }

    &-Blocks {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            grid-template-columns: 1fr;
        }

        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }
    }
}
