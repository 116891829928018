/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-tab-list-item-color: #404040;
}

.MyAccountTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        button {
            &::after {
                content: none;
            }
        }
    }

    &:not(:last-child) {
        @include mobile {
            border-bottom: 1px solid $greyF0F0F0;
        }
    }

    &_isActive {
        .MyAccountTabListItem-Button {
            @include desktop {
                font-weight: 700;
            }
        }
    }

    &-Button {
        padding: 16px 0;
        min-width: 100%;
        min-height: 38px;
        text-align: start;
        text-shadow: var(--text-shadow);
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        gap: 12px;
        align-items: center;

        @include desktop {
            padding: 10px 16px;
            border-left: 2px solid transparent;
        }

        .MyAccountTabListItem_isActive & {
            @include desktop {
                background: $icegrey;
                border-color: $black;
            }
        }

        .ChevronIcon {
            width: 16px;
            height: 16px;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 20px;

            @include desktop {
                display: none !important;
            }
        }

        &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-size: 100% auto;
            background-position: center center;
            background-repeat: no-repeat;

            .address-book & {
                background-image: url("/style/icons/account_addresses.png");
            }

            .my-orders & {
                background-image: url("/style/icons/account_orders.png");
            }

            .newsletter-subscription & {
                background-image: url("/style/icons/account_newsletter.png");
            }

            .my-wishlist & {
                background-image: url("/style/icons/account_wishlist.png");
            }

            .my-returns & {
                background-image: url("/style/icons/account_returns.png");
            }

            .logout & {
                background-image: url("/style/icons/account_logout.png");
            }

            .dashboard & {
                background-image: url("/style/icons/account_account.png");
            }
        }
    }
}
