/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, .59);
    --my-account-overlay-width: 454px;
    --my-account-overlay-border-width: 1px;
    --popup-bottom-offset: 40px;
}

.MyAccountOverlay {
    --overlay-background: var(--overlay-content-background);

    &.Overlay {
        justify-self: flex-end;
        grid-column: 3;
        right: 0;
        padding-top: 1rem;

        @include desktop {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
            padding: 0;
            background: none;
        }

        @include mobile {
            height: auto;
            padding-bottom: 0;
            top: 0;
            position: relative;
        }
    }

    &_isVisible {
        &.Overlay {
            @include desktop {
                border: var(--my-account-overlay-border-width) solid var(--overlay-desktop-border-color);
                width: 100%;
            }
        }
    }

    & > a {
        display: none;

        @include desktop {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            display: block;
        }
    }

    .Form {
        display: flex;
        flex-direction: column;
    }

    button {
        background-color: $black;
        color: $white;
        width: 100%;

        &:not([disabled]):hover {
            background-color: $white;
            color: $black;
        }

        &.Button_likeLink {
            background: none;
            color: $black;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            width: auto;
            letter-spacing: -0.0008em;
            text-decoration: underline;
        }
    }

    &-ForgotPassword {
        width: auto;
        margin-inline-start: auto;
        margin-block-start: 24px !important;
    }

    &-MigrationMessage {
        margin-block-start: 24px;
        font-size: 14px;
        text-align: left;
    }

    input {
        border-color: $black;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    select {
        font-weight: 600;
        font-size: 12px;
    }

    .Field {
        margin-top: 16px;
    }

    .Field-Label {
        padding-bottom: 4px;
    }

    &-Heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        padding-block-start: 0;
        margin-block-end: 0 !important;
    }

    &-Info {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 16px 0;

        // @include desktop {
        //     margin-top: 64px;
        // }
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 24px;

        @include mobile {
            padding: 28px;
        }
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: start;
            padding: 12px 24px;
            font-size: 14px;

            @include mobile {
                padding: 14px 28px;
                font-size: 17px;
            }

            &:hover {
                background-color: var(--color-cart-light);
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }

            @include desktop {
                padding: 6px;
            }
        }

        @include desktop {
            padding: 6px;
        }
    }

    &-SignInLink {
        width: auto;
        margin-block-start: 0;
    }

    &-ResetPassword {
        width: 100%;
        margin-block-start: 16px;
    }

    &-Action {
        display: grid;
        grid-template-rows: max-content 1fr;
        width: 100%;

        @include desktop {
            overflow-y: auto;
            max-height: calc(100vh - var(--header-nav-height) - var(--popup-bottom-offset));
            padding-inline: 16px;
            padding-block: 14px;
        }

        .CreateAccount & {
            .MyAccountOverlay-Buttons {
                button {
                    margin-bottom: 0;
                }
            }

            .Field {
                margin-block-start: 16px;

                .FieldSelect-Clickable {
                    display: block;
                }

                &.gender {
                    + .Field {
                        margin-top: 36px;
                    }
                }

                &.checkbox {
                    grid-column: 1/-1;
                    border-top: 1px solid $grey;
                    padding-top: 16px;
                    margin-bottom: 0;
                }

                &.email {
                    grid-column: 1/-1;
                }

                &.date {
                    .Image-Image {
                        width: auto;
                        height: auto;
                        top: 12px;
                        right: 19px;
                        left: auto;

                        + input[type="date"] {
                            background: none;
                        }
                    }
                }

                input {
                    &[type="date"] {
                        color: transparent;
                        padding-top: 9px;
                        padding-bottom: 8px;
                        min-height: 49px;

                        ~ .Field-Placeholder {
                            background: $white;
                            display: flex;
                            align-items: center;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            position: absolute;
                            left: 1px;
                            top: 50%;
                            transform: translateY(-50%);
                            padding: 9px 6px 8px 16px;
                            white-space: nowrap;
                        }
                    }

                    &::-webkit-calendar-picker-indicator {
                        font-size: 24px;
                        opacity: 0;
                    }
                }
            }

            .MyAccountOverlay-Legend {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 16px;
            }
        }

        &_state {
            &_createAccount {

                h3 {
                    margin-block-end: 12px;

                    @include mobile {
                        margin-block-end: 14px;
                    }
                }

                .Field {
                    margin-block-start: 16px;

                    .FieldSelect-Clickable {
                        display: block;
                    }

                    &.gender {
                        + .Field {
                            margin-top: 36px;
                        }
                    }

                    &.checkbox {
                        grid-column: 1/-1;
                        border-top: 1px solid $grey;
                        padding-top: 16px;
                        margin-bottom: 0;
                    }

                    &.email {
                        grid-column: 1/-1;
                    }

                    &.date {
                        .Image-Image {
                            width: auto;
                            height: auto;
                            top: 11px;
                            right: 19px;
                            left: auto;

                            + input[type="date"] {
                                background: none;
                            }
                        }
                    }

                    input {
                        &[type="date"] {
                            color: transparent;
                            padding-top: 9px;
                            padding-bottom: 8px;
                            min-height: 47px;

                            @include tablet {
                                padding-left: 12px;
                            }

                            ~ .Field-Placeholder {
                                background: $white;
                                display: flex;
                                align-items: center;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 16px;
                                position: absolute;
                                left: 1px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 9px 16px 8px;
                                white-space: nowrap;

                                @include tablet {
                                    padding: 9px 8px 8px 12px;
                                }
                            }
                        }

                        &::-webkit-calendar-picker-indicator {
                            font-size: 24px;
                            opacity: 0;
                        }
                    }
                }

                @include mobile {
                    padding-block-start: 14px;
                }

                .MyAccountOverlay-Legend {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 16px;
                }
            }

            &_forgotPassword {
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }

                @include desktop {
                    width: 180px;
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
        }

        h3 {
            margin-block-start: 0;
        }

        &.MyAccountOverlay-Action_state_signIn {
            display: flex;
            flex-direction: column;
        }
    }

    &-Additional {
        padding-block-start: 16px;

        section {
            display: flex;
            justify-content: center;

            padding-block-start: 20px;

            &:first-of-type {
                padding-block-start: 0;
            }
        }

        &_state {
            &_createAccount {
                padding-block-start: 24px;

                @include mobile {
                    padding-block-start: 16px;
                }

                h4 {
                    margin: 0;
                    padding-inline-end: 0;
                }

                button {
                    width: initial;
                    margin-block: 0;
                    margin-inline-start: 5px;

                    &:hover,
                    &:focus {
                        margin-block: 0;
                    }
                }
            }

            &_signIn {
                order: 1;

                section {
                    align-items: center;
                }

                button {
                    margin-inline-start: 5px;
                }
            }

            &_confirmEmail {
                padding-block-start: 0;
            }

            &_forgotPassword {
                section {
                    display: flex;
                    justify-content: flex-start;

                    button {
                        width: initial;
                        margin-block-start: 0;
                        font-weight: 500;
                        min-height: 48px;
                        padding: 9px;
                        letter-spacing: 0.04em;
                        text-decoration: none !important;

                        &:before {
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-style: solid;
                            border-width: 1px;
                            border-color: transparent $black $black transparent;
                            transform: rotate(135deg);
                            margin-left: 2px;
                            margin-right: 15px;
                        }

                        &:hover,
                        &:focus {
                            padding: 9px;
                        }

                        &:not([disabled]):hover {
                            background-color: $black;
                            color: $white;
                            padding: 9px;
                            text-decoration: none;

                            &:before {
                                border-color: transparent $white $white transparent;
                            }
                        }
                    }

                    h4 {
                        margin-block-end: 0;
                        align-self: center;
                    }

                    &:last-of-type {
                        align-items: center;
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-block-start: 0;

                h4 {
                    font-size: 12px;
                    line-height: 1.5;
                    padding-block-end: 17px;
                    font-weight: normal;
                    letter-spacing: normal;

                    @include mobile {
                        font-size: 14px;
                        padding-block-end: 20px;
                    }
                }
            }
        }
    }

    &-CloseButton {
        &::before,
        &::after {
            background-color: var(--header-color);
            content: '';
            height: 20px;
            position: absolute;
            inset-inline-end: 27px;
            inset-block-start: -13px;
            transform-origin: 50% 50%;
            width: 1px;
            z-index: 1;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Button {
        &_likeLink {
            font-size: 12px;
            margin-block-start: 14px;

            @include mobile {
                font-size: 13px;
                margin-block-start: 17px;
            }
        }
    }

    &-Checkbox {
        margin-block: 11px 24px;
    }

    &-Social {
        h4 {
            font-weight: 600;
            font-size: 12px;
            line-height: 25px;
            letter-spacing: -0.0008em;
            padding: 15px;
            text-align: center;
        }

        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            width: 50%;
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: center;
            height: 46px;
            border: 1px solid $grey;

            &:hover {
                text-decoration: none;
            }
        }

        &Buttons {
            display: flex;
            gap: 16px;
        }
    }
}

.Account__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;

    @include desktop {
        top: 16px;
        right: 16px;
    }
}
